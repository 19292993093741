<template>
  <div>
    <Echart 
      :options="options" 
      id="RightTopChart" 
      height="150px" 
      width="250px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart/index.vue'

export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    speed: {
      type: Number, 
      required: true
    },
    expected_time_left: {
      type: Number,
      required: true
    }
  },
  watch: {
    speed: {
      handler(newValue) {
        const maxValue = newValue + this.expected_time_left;
        const percentage = ((newValue / maxValue) * 100).toFixed(2);  // 计算百分比并保留两位小数

        this.options = {
          tooltip: {
            formatter: '{a} <br/>{b} : {c}%' // 显示百分比
          },
          grid: {
            right: '40%',
          },
          xAxis: {
            type: 'value',
            min: 0,
            max: maxValue * 1.0,  // 增加最大值，留出空间
            axisLabel: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#ccc',
                width: 2
              }
            },
            splitLine: {
              lineStyle: {
                color: '#ccc'
              }
            }
          },
          yAxis: {
            type: 'category',
            data: ['Speed Progress'],
            axisLabel: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#ccc',
                width: 2
              }
            }
          },
          series: [
            {
              name: 'Speed Progress',
              type: 'bar',
              data: [newValue],
              barWidth: '90%',  // 调整条形图宽度
              itemStyle: {
                color: '#4caf50'  // 进度条颜色
              },
              label: {
                show: true,
                position: 'right',
                formatter: `${percentage}%`,  // 显示动态计算的百分比
                fontSize: 16,  // 增大字体
                color: '#ffffff',  // 设置字体颜色为白色
                fontWeight: 'bold'  // 设置字体为加粗
              }
            }
          ]
        };
      },
      immediate: true  // 初始化时也会执行
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
