<template>
  <div>
    <Chart :beltSpeed="beltSpeed2"/>
  </div>
</template>

<script>
import Chart from './chart.vue'

export default {
  data() {
    return {
      cdata: {
        date: [],
        speed: [[],[],[],[],[]],
      },
      beltSpeed2:[]
    };
  },
  props: {
    beltSpeed: {
      type: Array
    },
  },
  created() {
    this.beltSpeed2 = this.beltSpeed
  },
  watch: {
    beltSpeed: {
      handler: function (newVal) {
        this.beltSpeed2 = newVal
        console.log("---------")
        // console.log(newVal)
        let now = new Date()
        const h = now.getHours();
        const m = now.getMinutes();
        const s = now.getSeconds();
        const time = `${h}:${m}:${s}`
        this.cdata.date.push(time)
        // console.log(this.cdata.date)
        
        this.cdata.speed[0].push(newVal[0].speed)
        this.cdata.speed[1].push(newVal[1].speed)
        this.cdata.speed[2].push(newVal[2].speed)
        this.cdata.speed[3].push(newVal[3].speed)
        this.cdata.speed[4].push(newVal[4].speed)
        console.log(this.cdata.speed)
      },
    }
  },
  components: {
    Chart,
  },
  mounted() {
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
