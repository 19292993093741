<template>
  <div id="rightTopRight">
    <div class="bg-color-black">
      <div class="top-box">
        <div class="chart-title">{{ this.title }}</div>
        <el-select v-model="value" placeholder="全部" @change="handleSelectChange">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div>
        <LeftTopChart :beltProductivity="this.beltProductivity"/>
      </div>
    </div>
  </div>
</template>


<script>
import {defineComponent} from 'vue';
import LeftTopChart from '_c/echart/beltlive/left/leftTop';
import beltlive from '../../api/beltlive';

export default defineComponent({
  name: "rightTopRight",
  data() {
    return {
      title: "每日产能趋势",
      options: [
        {
          value: '3000',
          label :'全部'
        },
        {
          value: '7',
          label :'一周内'
        },
        {
          value: '31',
          label: '一月内'
        },
        {
          value: '365',
          label: '一年内'
        },
      ],
      value: '3000',
      beltProductivity: [],
    }
  },
  components: {
    LeftTopChart
  },
  created() {
    this.fetchData(3000);
  },
  methods:{
    handleSelectChange() {
      this.$emit('select-change', this.value);
      // console.log(this.value)
      this.fetchData(this.value)
    },
    fetchData(value) {
      beltlive.getBeltProductivity(value).then(
        (response) => {
          if (response.data.data !== null) {
            this.beltProductivity = response.data.data
          } else {
            this.value = '3000'
            this.fetchData(this.value)
          }
          
        }
      )
    }
  }
})
</script>

<style scoped lang="scss">
$box-height: 450px;
$box-width: 100%;
#rightTopRight {
  padding: 20px 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 35px;
    border-radius: 10px;
  }

  .top-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;

    .chart-title {
    padding: 5px 0 0 20px;
    font-size: 24px;
    font-weight: bold;
    color: #25a18e;
    }
  }



}
</style>
