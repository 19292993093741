<template>
  <div class="chart-container">
    <Chart :otd="otd2"/>
  </div>
</template>

<script>
import Chart from './chart.vue'

export default {
  data() {
    return {
      cdata: {
        date: [],
        speed: [[],[],[],[],[]],
      },
      beltSpeed2:[]
    };
  },
  props: {
    otd: {
      type: Array
    },
  },
  created() {
    this.otd2 = this.otd
  },
  watch: {

  },
  components: {
    Chart,
  },
  mounted() {
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.chart-container {
  height: 50px;  /* 设置你希望的高度 */
}
</style>