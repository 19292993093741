<template>
  <div id="live" ref="appRef">
    <div class="bg">
      <div class="live-body">
        <MyHeader :title=title></MyHeader>
        <div class="body-box">
          <div class="content-box">
            <div class="left-box">
              <div class="left-top-box">
                <dv-border-box-12>
                  <leftTop :percent="percent"/>
                </dv-border-box-12>
              </div>
              <div class="left-bottom-box">
                <dv-border-box-12>
                  <leftBottom :percent="percent"/>
                </dv-border-box-12>
              </div>
            </div>
            <div class="right-box">
              <div class="right-top-box">
                <dv-border-box-12>
                  <right :beltTime="beltTime"/>
                </dv-border-box-12>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import drawMixin from "../../utils/drawMixin";
import MyHeader from "../../components/header/myHeader.vue";
import top from './top.vue'
import leftTop from './leftTop.vue'
import leftBottom from './leftBottom.vue'
import right from './right.vue';
import belt from "@/api/belt"
import beltlive from '../../api/beltlive';

export default defineComponent({
  name: "live",
  data() {
    return {
      title: "传送带效率",
      processedNum: [],
      percent: [],
      beltStatus: [],
      beltProductivity: [],
      beltStatusNum:[],
      beltTime: [],
    }
  },
  mixins: [drawMixin],
  components: {
    MyHeader, top, leftTop, leftBottom, right
  },
  mounted() {
    setInterval(() => {
      this.fetchData();
    }, 3000);
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      belt.getBeltInfo(1).then(
        (response) => {
          this.processedNum = response.data.data
          this.beltStatus = response.data.data
        }
      )
      belt.getPercent(1).then(
        (response) => {
          this.percent = response.data.data
        }
      )
      belt.getBeltStatus().then(
        (response) => {
          // console.log(response.data.data)
          this.beltStatusNum = response.data.data
        }
      )
      await beltlive.getBeltTime(1).then(
        (response) => {
          this.beltTime = response.data.data
        }
      )
    },
  },

})
</script>

<style scoped lang="scss">
@import '../../assets/scss/eff.scss';
</style>
