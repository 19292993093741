<template>
  <div class="order" ref="appRef">
    <div class="bg">
      <MyHeader :title="title"></MyHeader>

      <div class="order-container">
        <!-- 这个容器使用Flex布局 -->
        <div class="left-right-container">
          <!-- 左侧1/3空间，用于放置第一个代码中的left-box -->
          <div class="left-box-container">
            <div class="left-top-box">
              <dv-border-box-12>
                <leftTop :percent="percent"/>
              </dv-border-box-12>
            </div>
            <div class="left-bottom-box">
              <dv-border-box-12>
                <leftBottom :otd="tmp_otd"/>
              </dv-border-box-12>
            </div>
          </div>

          <!-- 右侧2/3空间，用于放置图表和任务信息 -->
          <div class="right-box-container">
            <!-- 描述部分 -->
            <div class="table-desc">
              <div class="table-title">{{ table_title }}</div>
              <div class="table-legend">
                <div class="color-info">
                  <div class="color-label">进行中</div>
                  <div class="color-block-success"></div>
                </div>
                <div class="color-info">
                  <div class="color-label">将截止</div>
                  <div class="color-block-warning"></div>
                </div>
                <div class="color-info">
                  <div class="color-label">已逾期</div>
                  <div class="color-block-danger"></div>
                </div>
              </div>
            </div>

            <!-- 表格部分 -->
            <div class="order-table">
              <el-table
                  :data="this.detail_tasks"
                  border
                  style="width: 100%"
                  :row-class-name="tableRowClassName">
                <el-table-column prop="order_number" label="订单号" width="130"></el-table-column>
                <el-table-column prop="order_time" label="订单时间" width="180"></el-table-column>
                <el-table-column prop="specification" label="规格" width="180"></el-table-column>
                <el-table-column prop="series" label="系列" width="70"></el-table-column>
                <el-table-column prop="required_quantity" label="需求数量" width="110"></el-table-column>
                <el-table-column prop="quantity" label="已生产数量" width="130"></el-table-column>
                <el-table-column prop="delivery_date" label="交货日期" width="140"></el-table-column>
                <el-table-column prop="reserve_three_days" label="是否预留三天" width="160">
                  <template slot-scope="scope">
                    <el-tag :type="scope.row.reserve_three_days ? 'success' : 'info'">
                      {{ scope.row.reserve_three_days ? '是' : '否' }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="status" label="订单状态" width="160"></el-table-column>
              </el-table>
            </div>

            <!-- 分页部分 -->
            <div class="block">
              <el-pagination
                  layout="prev, pager, next"
                  :total="total_page"
                  :page-size="this.detail_page_size"
                  @current-change="handleDetailChange">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyHeader from "../../components/header/myHeader.vue";
import drawMixin from "@/utils/drawMixin";
import order from "@/api/order";
import leftTop from './leftTop.vue';
import leftBottom from './leftBottom.vue';

export default {
  name: "order",
  mixins: [drawMixin],
  data() {
    return {
      title: "工件日志",
      table_title: "订单信息",
      detail_tasks: [],
      total_page: 12,
      detail_current_page: 1,
      detail_page_size: 10,
      percent: [],
      tmp_otd:{
        'day1':98,
        'day2':96,
        'day3':95,
        'day4':92,
        'day5':97,
        'day6':92,
        'day7':99,
      }
      
    }
  },
  components: {
    MyHeader,
    leftTop,
    leftBottom
  },
  mounted() {
    this.fetchDetailData();
  },
  methods: {
    tableRowClassName({ row }) {
      // 可根据订单状态修改样式
      if (row.status === "未完成") {
        return 'warning-row';
      } else if (row.status === "已完成") {
        return 'success-row';
      }
      return '';
    },
    handleDetailChange(val) {
      this.detail_current_page = val;
      this.fetchDetailData();
    },
    fetchDetailData() {
      order.getOrderInfoAnalysis(this.detail_page_size, this.detail_current_page)
        .then(response => {
          this.total_page = response.data.data.total;
          this.detail_tasks = response.data.data.item;
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
}
</script>


<style scoped lang="scss">
@import '../../assets/scss/order.scss';
</style>
