import http from '../utils/request.js'


const order = {
    getOrderInfoAnalysis(limit, start, status) {
        if (!start) start = 1
        const params = {
            status: status,
            limit: limit,
            start: start
        }
        return http.post(`/screen/productionOrderStatus`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
    },

}

export default order;
