import http from '../utils/request.js'


const productlion_new = {
    getStatus(start_, name) {
        if (!start_) start_ = 1; // 默认值
        if (name == `all`) name = ''
        const params = {
            start: start_,
            line_name: name
        };
        
        // 发送 GET 请求，不需要设置 Content-Type 对于 GET 请求
        return http.get('/productionLineStatus/productionLineStatuses', { params }, {
            headers : {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    }
};

export default productlion_new;
