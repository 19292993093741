<template>
  <div class="myHeader">
    <div class="d-flex jc-center">
      <dv-decoration-10 class="dv-dec-10" />
      <div class="d-flex jc-center">
        <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
        <div class="title">
          <span class="title-text">{{ this.title }}</span>
          <dv-decoration-6 class="dv-dec-6" :reverse="true" :color="['#50e3c2', '#67a1e5']" />
        </div>
        <dv-decoration-8 class="dv-dec-8" :reverse="true" :color="decorationColor" />
      </div>
      <div class="time-container">
        <dv-decoration-10 class="dv-dec-10-s" />
      </div>

    </div>

    <!-- 第二行 -->
    <div class="d-flex jc-between px-2">
      <div class="d-flex aside-width">
        <!-- 在这里添加一个天气预报组件 -->
        <div class="weather-container">
          <dv-decoration-10 class="dv-dec-10-s" />
          <div class="weather-box">
            <WeatherWidget />
          </div>
        </div>
        <div class="react-left  ml-3">
          <span class="react-left"></span>
          <span class="text" @click="navigateToEfficiency">传送带效率</span>
        </div>
        <div class="react-left ml-3">
          <span class="text" @click="navigateToBeltNew">传送带实况</span>
        </div>
      </div>
      <div class="d-flex aside-width">
        <div class="react-right  react-l-s mr-3">
          <span class="text fw-b" @click="navigateToItemNew">工件加工</span>
        </div>
        <div class="react-right  react-l-s mr-3">
          <span class="react-after"></span>
          <span class="text fw-b" @click="navigateToOrder">工件日志</span>
        </div>
        <div class="time-container">
          <dv-decoration-10 class="dv-dec-10-s" />
          <div class="time-box">
            <TimeWidget />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {formatTime} from "@/utils";
import WeatherWidget from './WeatherWidget.vue';
import TimeWidget from './TimeWidget.vue';

export default defineComponent({
  name: "myHeader",
  components: {
    WeatherWidget,
    TimeWidget,
  },
  props: {title: String},
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000'],
    }
  },
  mounted() {
    this.timeFn()
  },
  beforeDestroy() {
    clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    navigateToStatus() {
      this.$router.push('/status').catch(err => {
        console.log(err)
      });
    },
    navigateToOee() {
      this.$router.push('/oee').catch(err => {
        console.log(err)
      });
    },
    navigateToLight() {
      this.$router.push('/light').catch(err => {
        console.log(err)
      });
    },
    navigateToLive() {
      this.$router.push('/').catch(err => {
        console.log(err)
      });
    },
    navigateToInfo() {
      this.$router.push('/info').catch(err => {
        console.log(err)
      });
    },
    navigateToRank() {
      this.$router.push('/rank').catch(err => {
        console.log(err)
      });
    },
    navigateToOrder() {
      this.$router.push('/order').catch(err => {
        console.log(err)
      });
    },
    navigateToStock() {
      this.$router.push('/stock').catch(err => {
        console.log(err)
      });
    },
    navigateToMessage() {
      this.$router.push('/message').catch(err => {
        console.log(err)
      });
    },
    navigateToProcedure() {
      this.$router.push('/procedure').catch(err => {
        console.log(err)
      });
    },
    navigateToBelt() {
      this.$router.push('/belt').catch(err => {
        console.log(err)
      });
    },
    navigateToBeltLive() {
      this.$router.push('/beltlive').catch(err => {
        console.log(err)
      });
    },
    navigateToProductLine() {
      this.$router.push('/productline').catch(err => {
        console.log(err)
      });
    },
    navigateToBeltNew() {
      this.$router.push('/beltlive_new').catch(err => {
        console.log(err)
      });
    },
    navigateToItemNew() {
      this.$router.push('/item_new').catch(err => {
        console.log(err)
      });
    },
    navigateToEfficiency() {
      this.$router.push('/efficiency').catch(err => {
        console.log(err)
      });
    },
  }
})
</script>


<style scoped lang="scss">
@import '../../assets/scss/header.scss';
</style>
