<template>
  <div class="time-widget">
      <div class="time-content">
          <div class="date-display">{{ currentDate }}</div>
          <div class="time-display">{{ currentTime }}</div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'TimeWidget',
  data() {
      return {
          // 存储当前日期
          currentDate: '',
          // 存储当前时间
          currentTime: '',
      }
  },
  mounted() {
      // 组件挂载后调用获取当前日期和时间的方法
      this.getDateAndTime();
      // 每分钟更新日期和时间
      setInterval(this.getDateAndTime, 60000);
  },
  methods: {
      getDateAndTime() {
          // 获取当前日期和时间
          const now = new Date();
          // 格式化日期为 'YYYY年MM月DD日' 格式
          this.currentDate = now.getFullYear() + '年' + (now.getMonth() + 1) + '月' + now.getDate() + '日';
          // 格式化时间为 'HH:mm:ss' 格式
          this.currentTime = now.toLocaleTimeString();
      }
  }
}
</script>

<style scoped lang="scss">
.time-widget {
  padding: 10px 10px;
  display: flex;
  border-radius: 4px;
  width: 250px;
  margin-left: 0px;
  flex-direction: column; /* 垂直布局 */
  justify-content: space-between; /* 内容上下分布 */
  align-items: flex-end; /* 右对齐 */

  .time-content {
    justify-content: flex-end;
    .date-display {
      font-size: 20px; // 设置日期字体大小
      color: #fff; // 设置日期字体颜色为白色
      margin-bottom: 5px;
    }
    
    .time-display {
      font-size: 28px; // 设置时间字体大小
      font-weight: bold; // 设置时间字体加粗
      color: #fff; // 设置时间字体颜色为白色
      margin-left: 35px;
    }
  }
}
</style>