<template>
  <div>
    <Echart
        :options="options"
        id="RightBottomChart"
        height="400px"
        width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart/index.vue'

export default {
  data() {
    return {
      options: {},
      otdData: {},
      days: [],
      values: [],
    };
  },
  components: {
    Echart,
  },
  props: {
    otd: {
      type: Object,
    },
  },
  watch: {
    otd: {
      handler(newData) {
        // Extract the days and values from the otd object
        this.days = Object.keys(newData); // ['day1', 'day2', 'day3', 'day4']
        this.values = Object.values(newData); // [98, 96, 95, 99]

        // Set the chart options
        this.options = {
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            top: '2%',
            data: ['OTD'],
            textStyle: {
              fontSize: 14,
            }
          },
          grid: {
            top: '10%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            splitLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                fontSize: 16,
                fontWeight: 'bold',
              }
            },
            data: this.days, // Use the extracted days
          },
          yAxis: {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 16,
              },
              formatter: '{value}',
            },
          },
          series: [
            {
              name: 'OTD',
              type: 'line',
              symbol: 'none',
              data: this.values, // Use the extracted values
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                }
              },
            }
          ]
        }
      },
      immediate: true,
    },
  },
}
</script>
