<template>
  <div class="mid-box">
    <div class="bg-color-black">
      <div class="mid-desc">
        <div class="mid-title">{{ this.title }}</div>
        <el-select v-model="value" placeholder="全部" @change="handleSelectChange">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div class="container">
        <div v-for="item in visibleProductionLines" :key="item.id" class="box">
          <div class="left">
            <p class="item">生产线名称：{{ item.line_name }}</p>
            <p class="item">待加工零件：{{ item.pending_parts }}</p>
            <p class="item">已加工零件：{{ item.processed_parts }}</p>
            <p class="item">生产线状态：{{ getStatus(item.line_status) }}</p>
            <p class="item">已运行时间：{{ item.operating_time }} 分钟</p>
          </div>
          <div class="right">
            <div class="right-down">
              <button class="action-button" @click="change_speed_windows_on(item)">更改状态</button>
            </div>
          </div>
          <div class="mid_speed">
            <Mid :speed="item.operating_time" :expected_time_left="item.expected_time_left " style="height: 40px;  margin-bottom: 10px;"/>
            <Mid :speed="item.processed_parts" :expected_time_left="item.pending_parts" style="height: 40px; margin-top: 10px;"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Mid from "_c/echart/productionlinenew/leftchart/index.vue";
import beltlive from '../../api/beltlive';
import productlion_new from '../../api/productline_new';

export default defineComponent({
  name: "mid",
  data() {
    return {
      title: "生产线信息",
      productionLineStatus: [], // 全部生产线状态
      visibleProductionLines: [], // 当前显示的生产线
      maxVisibleLines: 4, // 最大显示数量
      animationSpeed: 1,
      ringColor: '#e74c3c',
      speed_change_windows: false,
      change_item_id: 0,
      speed_temp: 0,
      speed_old: 0,
      linename: 0,
      options: [
        { value: 'all', label: '全部' },
        { value: 'line1', label: '产线1' },
        { value: 'line2', label: '产线2' },
        { value: 'line3', label: '产线3' },
        { value: 'line4', label: '产线4' },
      ],
      value: 'all',
    };
  },
  components: {
    Mid,
  },
  mounted() {
    this.initData();
    setInterval(() => {
      this.updateVisibleLines();
    }, 3000);
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      try {
        await this.fetchData(this.value);
        this.updateVisibleLines();
      } catch (error) {
        console.error("初始化数据失败:", error);
      }
    },
    async fetchData(value = 'all') {
      try {
        const response = await productlion_new.getStatus(1, value);
        if (response?.data?.data?.item) {
          this.productionLineStatus = response.data.data.item;
        } else {
          console.warn("接口返回数据格式异常:", response);
          this.productionLineStatus = [];
        }
      } catch (error) {
        console.error("获取生产线数据失败:", error);
        this.productionLineStatus = [];
      }
    },
    getStatus(status) {
      switch (status) {
        case 0: return "运行";
        case 1: return "故障";
        case 2: return "闲置";
        case 3: return "暂停";
        case 4: return "维修中";
        default: return "未知";
      }
    },
    change_speed_windows_on(item) {
      this.change_item_id = item.id;
      this.speed_temp = item.operating_time;
      this.speed_change_windows = true;
      this.speed_old = item.operating_time;
    },
    async updateSpeed(speed, id) {
      try {
        await beltlive.changeBeltSpeed(1, id, speed);
        this.speed_change_windows = false;
      } catch (error) {
        console.error("更新速度失败:", error);
      }
    },
    updateVisibleLines() {
      this.visibleProductionLines = this.productionLineStatus.slice(0, this.maxVisibleLines);
    },
    async handleSelectChange() {
      try {
        await this.fetchData(this.value);
      } catch (error) {
        console.error("筛选数据失败:", error);
      }
      this.updateVisibleLines();
    },
  },
});
</script>

<style scoped lang="scss">
$box-height: 880px;
$box-width: 933px;

.mid-box {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 20px;
    border-radius: 10px;
  }

  .mid-desc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    .mid-title {
      padding: 5px 0 0 20px;
      font-size: 24px;
      font-weight: bold;
      color: #25a18e;
    }

    .mid-detail {
      padding: 5px 0 0 20px;
      cursor: pointer;
      font-size: 20px;
      color: #25a18e;
    }
  }

}

.container {
  .box {
    display: flex;
    border: 1px solid white;
    border-radius: 15px;
    margin: 10px;

    .left {
      width: 38%;
      font-size: 20px;
      font-weight: bold;

      .item {
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 40px;
      }

    }

    .mid_speed {
      width: 30%;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-left: 40px;
      height: 100px;
    }

    .right {
      width: 33%;
      color: #25a18e;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .right-down {
        width: 100%;
        height: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px 0;
      }
    }
  }

  .action-button {
    width: 70%;
    padding: 10px;
    margin: 5px 0;
    background-color: #192954;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;

    &:hover {
      background-color: #1e8c6e;
    }
  }

}
</style>
