<template>
  <div class="lb-box">
    <div class="bg-color-black">
      <div class="lb-content">
        <div class="lb-desc">
          <div class="lb-title">报表</div>
        </div>
        <div class="lb-table">
          <el-table :data="tableData" border>
            <el-table-column label="系列" prop="series" width="118"></el-table-column>
            <el-table-column label="接单数量" prop="orderCount" width="140"></el-table-column>
            <el-table-column label="完成数量" prop="completedCount" width="160"></el-table-column>
            <el-table-column label="完成率" prop="completionRate" width="130">
              <template #default="{ row }">
                <span>{{ row.completionRate }}%</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "leftBottom",
  data() {
    return {
      // 示例数据
      tableData: [
        { series: "系列 A", orderCount: 100, completedCount: 80, completionRate: 80 },
        { series: "系列 B", orderCount: 120, completedCount: 100, completionRate: 83.33 },
        { series: "系列 C", orderCount: 90, completedCount: 70, completionRate: 77.78 },
        { series: "系列 D", orderCount: 150, completedCount: 140, completionRate: 93.33 }
      ]
    }
  }
});
</script>

<style scoped lang="scss">
$box-height: 440px;
$box-width: 600px;
.lb-box {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }

  .lb-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;

    .lb-desc {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;

      .lb-title {
        padding: 5px 0 0 20px;
        font-size: 24px; /* 字体变小 */
        font-weight: bold;
        color: #25a18e;
        margin-top: 10px;
      }
    }

    .lb-table {
      flex: 1;
      overflow: auto; /* 确保表格内容不会溢出 */
      padding: 10px;

      /* 表格样式 */
      .el-table {
        border: none;
      }

      .el-table th, .el-table td {
        text-align: center;
        font-size: 12px; /* 字体变小 */
      }
    }
  }
}
</style>
