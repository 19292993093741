import http from '../utils/request.js'


const beltlive = {

    getBeltProductivity(time) {
      const params = {
        time_range: time,
      }
      return http.post(`/screen/beltProductivity`, params, {
        headers : {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
    },

    getBeltSpeed(id) {
      if (!id) id = 1
      const params = {
        production_line_id: id,
      }
      return http.post(`/screen/beltSpeed`, params, {
        headers : {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
    },

    getBeltTime(id) {
      if (!id) id = 1
      const params = {
        production_line_id: id,
      }
      return http.post(`/screen/beltTime`, params, {
        headers : {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
    },

    changeBeltSpeed(production_line_id , id, speed) {
      const params = {
        production_line_id: id,
        speed: speed,
      }
      return http.put(`/conveyorBelt/${id}`, params, {
        headers : {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
    }

}

export default beltlive;
