<template>
  <div>
    <Echart 
      :options="options" 
      id="RightTopChart" 
      height="150px" 
      width="200px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart/index.vue'

export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    speed: {
      type: Number
    },
  },
  watch: {
    speed: {
      handler(newValue) {
        this.options = {
          series: [
            {
              type: 'gauge',
              min: 0,
              max: 100,
              progress: {
                show: true,
                width: 3
              },
              axisLine: {
                lineStyle: {
                  width: 3
                }
              },
              axisTick: {
                show: false
              },
              splitLine: {
                length: 4,
                lineStyle: {
                  width: 1,
                  color: '#999'
                }
              },
              axisLabel: {
                distance: 2,
                color: '#999',
                fontSize: 11
              },
              anchor: {
                show: true,
                showAbove: true,
                size: 1,
                itemStyle: {
                  borderWidth: 2
                }
              },
              title: {
                show: false
              },
              detail: {
                valueAnimation: true,
                fontSize: 20,
                offsetCenter: [0, '75%'],
                color: "white"
              },
              data: [
                {
                  value: newValue
                }
              ]
            }
          ]
        }
      },
      immediate: true
    },
  },
}

</script>