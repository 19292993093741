<template>
  <div>
    <Chart :speed="speed2" :expected_time_left="expectedTimeLeft" />
  </div>
</template>

<script>
import Chart from './chart.vue'

export default {
  data() {
    return {
      speed2: 0,  // 当前速度
      expectedTimeLeft: 0,  // 剩余预期时间
    };
  },
  components: {
    Chart,
  },
  props: {
    speed: {
      type: Number,
      required: true
    },
    expected_time_left: {
      type: Number,
      required: true
    }
  },
  created() {
    // 初始化时给 speed2 和 expectedTimeLeft 赋值
    this.speed2 = this.speed;
    this.expectedTimeLeft = this.expected_time_left;
  },
  watch: {
    speed: {
      handler(newVal) {
        this.speed2 = newVal;
      }
    },
    expected_time_left: {
      handler(newVal) {
        this.expectedTimeLeft = newVal;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
