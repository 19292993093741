<template>
  <div class="weather-widget">
      <div class="weather-content">
          <div class="weather-icon">
              <!-- 使用 Font Awesome 图标 -->
              <i :class="weatherIcon"></i>
          </div>
          <div class="weather-info">
              <div class="temperature">{{ temperature }}°C</div>
              <div class="weather-desc">{{ weatherDesc }}</div>
              <div class="location">{{ location }}</div>
          </div>
      </div>
  </div>
</template>

<script>
// 引入 Font Awesome 的 CSS 文件
import 'fontawesome-free/css/all.css';

export default {
  name: 'WeatherWidget',
  data() {
      return {
          temperature: '--',
          weatherDesc: '加载中...',
          location: '天津',
          // 设置默认图标为晴天图标
          weatherIcon: 'fas fa-sun'
      }
  },
  mounted() {
      // 组件挂载后调用获取天气数据的方法
      this.getWeatherData();
  },
  methods: {
      getWeatherData() {
          // 这里模拟从天气API获取数据
          // 假设我们得到了以下数据
          const weatherData = {
              temperature: 3,
              weatherDesc: '晴',
              icon: 'fas fa-sun' // 根据天气情况设置图标
          };
          // 更新数据
          this.temperature = weatherData.temperature;
          this.weatherDesc = weatherData.weatherDesc;
          this.weatherIcon = weatherData.icon;
      }
  }
}
</script>

<style scoped lang="scss">
.weather-widget {
padding: 10px 10px;
display: flex;
border-radius: 4px;
width: 250px;
margin-left: 20px;

.weather-content {
  display: flex;
  align-items: center;
  
  .weather-icon {
    font-size: 24px;
    margin-right: 10px;
    color: #fff; // 设置图标颜色为白色
  }
  
  .weather-info {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 5px;
    
    .temperature {
      font-size: 36px;
      font-weight: bold;
      color: #fff;
    }
    
    .weather-desc {
      font-size: 24px;
      color: #eee;
    }
    
    .location {
      font-size: 24px;
      color: #ddd;
    }
  }
}
}
</style>